import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
	AppBar,
	Box,
	Button,
	Container,
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
} from "@mui/material";
import React from "react";
import jmLogo from "./images/jm_logo.png";
import landscaping1 from "./images/landscaping1.jpeg";
import landscaping2 from "./images/landscaping2.jpg";
import landscaping3 from "./images/landscaping3.jpg";
import twoguys from "./images/twoguys.jpg";

const colors = {
	text: "#000",
	appBarBackground: "#000052",
	appBarText: "#fff",
	background1: "#eee",
	background2: "#bbb",
};

const App = () => {
	return (
		<div className="App">
			<AppBar
				position="fixed"
				sx={{ backgroundColor: colors.appBarBackground }}
			>
				<Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
					J&M Landscaping
				</Typography>
				<Box>
					<a
						href="mailto:info@jmlandscaping.com"
						target="_blank"
						rel="noreferrer"
						style={{
							textDecoration: "none",
						}}
					>
						<Button sx={{ color: colors.appBarText }}>
							Contact Us!
						</Button>
					</a>
				</Box>
			</AppBar>
			<Box
				name="section0"
				sx={{
					backgroundImage: `linear-gradient(${colors.background1}, ${colors.background2})`,
					color: colors.text,
					height: "100%",
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-around",
					alignItems: "center",
				}}
			>
				<Container maxWidth="md">
					<img
						src={jmLogo}
						alt="JM Landscaping Logo"
						style={{ width: "100%" }}
					/>
					<Typography variant="h6" sx={{ marginTop: 4 }}>
						J&M Landscaping is your source for all your landscaping
						needs! With 10 years of landscaping experience between
						them, Josh and Michael can take care of your needs with
						expertise and professionalism.
					</Typography>
					<Typography sx={{ marginTop: 5 }}>
						Scroll down for more infomation about our business or
						click "Contact Us" for service inquiries!
					</Typography>
				</Container>
			</Box>

			<Box
				name="section1"
				sx={{
					backgroundImage: `linear-gradient(${colors.background2}, ${colors.background1})`,
					color: colors.text,
					paddingTop: 10,
					paddingBottom: 10,
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-around",
					alignItems: "center",
				}}
			>
				<Container maxWidth="md">
					<Typography variant="h5" sx={{ marginBottom: 2 }}>
						Josh and Michael
					</Typography>
					<img
						src={twoguys}
						alt="Josh and Michael"
						style={{ maxWidth: "100%", maxHeight: 300 }}
					/>
					<Typography variant="body1" sx={{ marginTop: 2 }}>
						Josh and Michael are here to take care of your
						landscaping desires. With 10 years of experience between
						them, they started J&M Landscaping to help bring better
						service to the Fall River area. For small yards and
						large estates, Josh and Michael are ready ensure your
						property is ready to be enjoyed.
					</Typography>
				</Container>
			</Box>
			<Box
				name="section2"
				sx={{
					backgroundImage: `linear-gradient(${colors.background1}, ${colors.background2})`,
					color: colors.text,
					paddingTop: 10,
					paddingBottom: 10,
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-around",
					alignItems: "center",
				}}
			>
				<Container maxWidth="md">
					<Typography variant="h5" sx={{ marginBottom: 2 }}>
						Services
					</Typography>
					<img
						src={landscaping3}
						alt="Landscaping 1"
						style={{ maxWidth: "100%", maxHeight: 300 }}
					/>
					<Typography variant="body1" sx={{ marginTop: 2 }}>
						Check out a list of some of our services! If you don't
						see your need on here, that doesn't mean we can't do it,
						so drop us a message using the "Contact Us" button
						above!
					</Typography>
					<Grid container sx={{paddingTop: 2}}>
						<Grid item xs={12} sm={6}>
							<List dense sx={{padding: 0}}>
								<ListItem>
									<ListItemText
										sx={{ textAlign: "center" }}
										primary="Mowing"
									/>
								</ListItem>
								<ListItem>
									<ListItemText
										sx={{ textAlign: "center" }}
										primary="Raking"
									/>
								</ListItem>
								<ListItem>
									<ListItemText
										sx={{ textAlign: "center" }}
										primary="Pruning"
									/>
								</ListItem>
							</List>
						</Grid>
						<Grid item xs={12} sm={6}>
							<List dense sx={{padding: 0}}>
								<ListItem>
									<ListItemText
										sx={{ textAlign: "center" }}
										primary="Trimming"
									/>
								</ListItem>
								<ListItem>
									<ListItemText
										sx={{ textAlign: "center" }}
										primary="Yard Cleanup"
									/>
								</ListItem>
								<ListItem>
									<ListItemText
										sx={{ textAlign: "center" }}
										primary="Snow Shoveling"
									/>
								</ListItem>
							</List>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Box
				name="section3"
				sx={{
					backgroundImage: `linear-gradient(${colors.background2}, ${colors.background1})`,
					color: colors.text,
					paddingTop: 10,
					paddingBottom: 10,
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-around",
					alignItems: "center",
				}}
			>
				<Container maxWidth="md">
					<Typography variant="h5" sx={{ marginBottom: 2 }}>
						Ready for a Beautiful Yard?
					</Typography>
					<img
						src={landscaping1}
						alt="Landscaping 1"
						style={{ maxWidth: "100%", maxHeight: 300 }}
					/>
					<Typography variant="body1" sx={{ marginTop: 2 }}>
						Contact us today using the button above to get scheduled
						with us. We're looking forward to working with you!
					</Typography>
				</Container>
			</Box>

			<Box
				sx={{
					backgroundImage: `linear-gradient(${colors.background1}, ${colors.background2})`,
					color: colors.text,
					height: 100,
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Typography>
					Website Design by Scott Hubert @ Weinhaus Creations
				</Typography>
			</Box>
		</div>
	);
};

export default App;
